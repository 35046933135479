import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

import "./styles/layout.scss";

import CourseList from "./components/CourseList";

// import CourseContent from "./components/CourseContent";

import CoursePage from "./CoursePage";

import ChapterPage from "./ChapterPage";
import LessonPage from "./LessonPage";

import DashboardPage from "./DashboardPage"; // Import the DashboardPage component

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CourseList />} />
        <Route path="/course/:id" element={<CoursePage />} />
        <Route path="/course/:id/chapter/:chapterId" element={<ChapterPage />} />
        <Route path="/course/:id/chapter/:chapterId/lesson/:lessonId" element={<LessonPage />} />
        {/* <Route
          path="/course/:id/chapter/:chapterId"
          element={<CourseContent />}
        />
        <Route
          path="/course/:id/chapter/:chapterId/lesson/:lessonId"
          element={<CourseContent />}
        /> */}
        {/* <Route path="/course/content/:id" element={<CourseContent />} /> */}
        {/* <Route path="/course/chapter/:id" element={<ChapterPage />} /> */}
        {/* <Route path="/course/lesson/:id" element={<LessonPage />} /> */}
        <Route path="/dashboard" element={<DashboardPage />} /> {/* Add the dashboard route */}
        <Route path="*" element={<h1>404 - Not Found</h1>} />
      </Routes>
    </Router>
  );
}

export default App;
