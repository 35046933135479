import React, { useState, useEffect } from "react";

import { useParams, Link, useNavigate } from "react-router-dom";

import PlaceholderImage from "./assets/placeholder.svg";
import { Container, Row, Col } from "react-bootstrap";

import CourseOutline from "./components/CourseOutline";

import ProgressBar from "./components/ProgressBar";

function ChapterPage() {
  const { id, chapterId } = useParams();
  const [course, setCourse] = useState(null);
  const [chapter, setChapter] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    fetch(`https://lms.newdevelop.nl/wp-json/newlearn-lms/v1/api/course/frontend/${id}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`, // Add the Authorization header with a placeholder token
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const courseData = data.data;
        setCourse(courseData);
        const chapterData = courseData.chapters.find((chapter) => chapter.id === chapterId);
        setChapter(chapterData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        setLoading(false);
      });
  }, [id, chapterId]);

  // Mimmic progress bar loading state.
  const [progress] = useState(25);

  if (loading) {
    return <div className="d-none">Loading...</div>;
  }

  if (!chapter) {
    return <div>Chapter not found</div>;
  }

  const handlePreviousClick = () => {
    const chapterIndex = course.chapters.findIndex((chapter) => chapter.id === chapterId);
    if (chapterIndex > 0) {
      const previousChapter = course.chapters[chapterIndex - 1];
      const lastLesson = previousChapter.lessons[previousChapter.lessons.length - 1];
      navigate(`/course/${id}/chapter/${previousChapter.id}/lesson/${lastLesson.id}`);
    } else {
      navigate(`/course/${id}`);
    }
  };

  const handleNextClick = () => {
    if (chapter && chapter.lessons.length > 0) {
      const firstLesson = chapter.lessons[0];
      navigate(`/course/${id}/chapter/${chapterId}/lesson/${firstLesson.id}`);
    }
  };

  return (
    <>
      <ProgressBar progress={progress} />
      <Container className="my-5">
        <Row>
          <Col md={4}>
            <CourseOutline courseData={course.chapters} />
          </Col>
          <Col md={8}>
            <h1 className="fw-bold mb-3">{chapter.title}</h1>
            <img
              src={chapter.image_url || PlaceholderImage}
              alt={chapter.title}
              className="img-chapter img-fluid rounded-4 w-100 object-fit-cover"
            />
            <p className="mt-4 fs-lg">{chapter.description}</p>

            <div className="d-flex justify-content-between mt-5">
              <Link to={`/course/${chapter.course_id}`} className="btn btn-outline-secondary text-body">
                Terug naar begin
              </Link>
              <div>
                {/* <button className="btn btn-secondary disabled">Vorige</button> */}
                <button className="btn btn-secondary" onClick={handlePreviousClick}>
                  Vorige
                </button>
                <button
                  className="btn btn-secondary ms-3"
                  onClick={handleNextClick}
                  disabled={!chapter.lessons || chapter.lessons.length === 0}
                >
                  Volgende
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ChapterPage;
