import React from "react";

import { ListGroup, Form, ListGroupItem, Alert } from "react-bootstrap";

function CourseOutline({ className, courseData }) {
  if (!courseData || !courseData.length) {
    return (
      <Alert variant="warning">
        Deze cursus heeft nog geen inhoud. Probeer het later opnieuw.
      </Alert>
    );
  }

  return (
    <div className={`${className} course-outline`}>
      <div className="border border-2 border-tertiary-light rounded-4">
        <div className="title py-2">Inhoud</div>
        <ListGroup className="chapter pb-2" variant="flush">
          {courseData.map((chapter) => (
            <React.Fragment key={chapter.id}>
              <ListGroupItem
                className={`chapter-header d-flex align-items-center ${
                  chapter.completed ? "checked" : ""
                }`}
              >
                <Form.Check
                  type="checkbox"
                  name="hoofdstuk"
                  className="mr-2"
                  disabled
                  checked={chapter.completed}
                />
                {chapter.title}
              </ListGroupItem>
              {chapter.lessons && chapter.lessons.length > 0 ? (
                chapter.lessons.map((lesson) => (
                  <ListGroupItem
                    key={lesson.id}
                    className="chapter-item d-flex align-items-center"
                  >
                    <Form.Check
                      type="checkbox"
                      name="les"
                      className="mr-2"
                      disabled
                      checked={lesson.completed}
                    />
                    {lesson.title}
                  </ListGroupItem>
                ))
              ) : (
                <>
                  {/* <ListGroupItem className="chapter-item d-flex align-items-center">
                    Dit hoofdstuk bevat nog geen lessen.
                  </ListGroupItem> */}
                </>
              )}
            </React.Fragment>
          ))}
        </ListGroup>
      </div>
    </div>
  );
}

export default CourseOutline;
