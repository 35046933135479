// src/DashboardPage.js
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

function DashboardPage() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch user data from the API
    fetch("https://lms.newdevelop.nl/wp-json/newlearn-lms/v1/api/user/dashboard", {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`, // Add the Authorization header with a placeholder token
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userData) {
    return <div>No user data found</div>;
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Dashboard</h1>
          <Card>
            <Card.Body>
              <h2>Welcome, {userData.name}</h2>
              <p>Email: {userData.email}</p>
              {/* Add more user data as needed */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default DashboardPage;
